import React, { useState } from "react";
import "../../FloatingLabelInputStyled.css";

const OTPInput = ({ numBoxes = 4, onChange }) => {
    const [otp, setOtp] = useState(new Array(numBoxes).fill(""));

    const handleChange = (element, index) => {
        const value = element.value.replace(/\D/g, ""); // Allow only numbers
        if (value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            onChange(newOtp.join(""));
            if (value && element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && e.target.previousSibling) {
            e.target.previousSibling.focus();
        }
    };

    return (
        <div className="otp-container" style={{ display: "flex", gap: "10px" }}>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className="floating-input otp-box"
                    style={{
                        textAlign: "center",
                        width: "50px",
                        borderBottom: "2px solid #ccc",
                        fontSize: "20px",
                    }}
                />
            ))}
        </div>
    );
};

export default OTPInput;
