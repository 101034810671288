// src/PdfViewer.js
import React from 'react';
import Navbar from './NavBar';
import Footer from './Footer';

const PdfViewer = ({ file }) => {
    const height = window.innerHeight;

    return (
        <div style={{
            height: '100vh', backgroundColor: "#1b1b1b"
        }}>
            <Navbar />
            <iframe
                src={file}
                width="100%"
                height={height * 0.82}
                style={{ border: 'none' }}
                title="PDF Viewer"
            />
            <Footer />
        </div>
    );
};

export default PdfViewer;