import React, { useState, useEffect } from 'react';
import './Home.css';
import Marquee from "react-fast-marquee";
import GlitchText from './GlitchText';
import { Link } from 'react-router-dom';

const Footer = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // This function will update the state with the current window width
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    // Add event listener on mount and cleanup on unmount
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <nav className="navbar">
            {/* if mobile render this */}
            {windowWidth < 500 && (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* <div className="navbar-logo">
                            <GlitchText text="lynkd" />
                        </div> */}
                        {/* <div className="navbar-buttons">
                            <button className="policies-btns">Contact</button>
                        </div> */}
                    </div>
                    {/* <Marquee className="marquee">
                        NYKAA | LENSKART | MAMAEARTH | SHEIN | WARBY PARKER | GLOSSIER | ALLBIRDS | CASPER | GYMSHARK | TATA CLIQ | FYN | CARS24 | URBAN LADDER | EVERLANE | REFORMATION | ZILINGO | BOMBAY SHAVING COMPANY | THE MAN COMPANY | MYGLAMM | ZIVAME | BARKBOX | MEUNDIES | NAADAM | ALO YOGA | STITCH FIX | ROTHY'S | BONOBOS | OUTDOOR VOICES | HIMS & HERS | GRANA | SOKO | FABLETICS | POP & SUKI | CARAA | VRAI | HUSH PUPPIES | CUYANA | M.GEMI | ETTITUDE | BANDO | THE SILL | WOKE UP LIKE THIS | GOSSAMER | ZARA HOME | HATCH | CHUMBAK | HAPIPOLA | PORTEA | BIRA 91 | LULA'S
                    </Marquee> */}
                    {/* <div className="navbar-buttons">
                        <button className="policies-btns">Contact</button>
                    </div> */}
                    <Link className="navbar-buttons" style={{ textDecoration: 'none' }} to={"/policies/terms-and-condition"}>
                        <button className="policies-btns">Terms and Conditions</button>
                    </Link>
                    <Link className="navbar-buttons" style={{ textDecoration: 'none' }} to={"/policies/privacy-policy"}>
                        <button className="policies-btns">Privacy Policy</button>
                    </Link>
                    <Link className="navbar-buttons" style={{ textDecoration: 'none' }} to={"/policies/refund-and-return"}>
                        <button className="policies-btns">Refund and Return</button>
                    </Link>
                    <Link className="navbar-buttons" style={{ textDecoration: 'none' }} to={"/policies/shipping-and-delivery"}>
                        <button className="policies-btns">Shipping and Delivery</button>
                    </Link>
                </>
            )}
            {/* if desktop render this */}
            {windowWidth > 500 && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '0 20px',
                    margin: 'auto'
                }}>
                    {/* <div className="navbar-logo">
                        <GlitchText text="lynkd" />
                    </div> */}
                    {/* <div className="navbar-buttons">
                        <button className="policies-btns">Contact</button>
                    </div> */}
                    <Link className="navbar-buttons" style={{ textDecoration: 'none' }} to={"/policies/terms-and-condition"}>
                        <button className="policies-btns">Terms and Conditions</button>
                    </Link>
                    <Link className="navbar-buttons" style={{ textDecoration: 'none' }} to={"/policies/privacy-policy"}>
                        <button className="policies-btns">Privacy Policy</button>
                    </Link>
                    <Link className="navbar-buttons" style={{ textDecoration: 'none' }} to={"/policies/refund-and-return"}>
                        <button className="policies-btns">Refund and Return</button>
                    </Link>
                    <Link className="navbar-buttons" style={{ textDecoration: 'none' }} to={"/policies/shipping-and-delivery"}>
                        <button className="policies-btns">Shipping and Delivery</button>
                    </Link>
                </div>
            )}
        </nav>
    );
}

export default Footer;