import { Share } from '@capacitor/share';
import React from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from 'react-share';
const SubmitResponse = ({ status, message }) => {
    const defaultSuccessMessage = "We will notify once your account is verified! Meanwhile let your followers know about it.";
    const defaultErrorMessage = "Uh oh, something went wrong with your submission.";

    const share = async () => {
        await Share.share({
            title: 'Form Submission',
            text: 'Form Submitted Successfully!',
            url: window.location.href,
            dialogTitle: 'Share this page with your friends!',
        });
    }

    // window width
    const [windows, setWindows] = React.useState({ width: 0 });
    React.useEffect(() => {
        const handleResize = () => {
            setWindows({ width: window.innerWidth });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={styles.container}>
            {status === 'success' ? (
                <div style={styles.success}>
                    {/* Success Icon */}
                    {/* <svg
                        fill="green"
                        style={{ width: "80px", height: "80px", marginBottom: "20px" }}
                        viewBox="0 0 24 24"
                    >
                        <circle cx="12" cy="12" r="10" stroke="green" strokeWidth="2" fill="none" />
                        <path d="M9 12l2 2l4 -4" stroke="green" strokeWidth="2" fill="none" />
                    </svg> */}
                    <h2>We got your submission!</h2>
                    <p style={{ fontSize: "0.9em", margin: 0 }}>{message || defaultSuccessMessage}</p>

                    {/* Share Icons */}
                    {windows.width > 500 && (
                        <div style={{ display: 'flex', justifyContent: 'left', height: 60 }}>
                            <p style={{ fontSize: "0.9em" }}>Share this page with your friends!</p>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: 250 }}>
                                <FacebookShareButton url={"https://lynkd.app"} style={{ width: 40 }} title='I’ve joined Lynkd,  "The Real Social Commerce," where shopping and social interaction unite! Follow me for the latest trends and exclusive content. Join now and experience the future of social commerce. Let’s connect!'>
                                    <FacebookIcon size={30} round />
                                </FacebookShareButton>
                                <WhatsappShareButton url={"https://lynkd.app"} style={{ width: 40 }} title='I’ve joined Lynkd,  "The Real Social Commerce," where shopping and social interaction unite! Follow me for the latest trends and exclusive content. Join now and experience the future of social commerce. Let’s connect!'>
                                    <WhatsappIcon size={30} round />
                                </WhatsappShareButton>
                                <TwitterShareButton url={"https://lynkd.app"} style={{ width: 40 }} title='I’ve joined Lynkd,  "The Real Social Commerce," where shopping and social interaction unite! Follow me for the latest trends and exclusive content. Join now and experience the future of social commerce. Let’s connect!'>
                                    <img src="https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1725753600&semt=ais_hybrid" alt="Twitter" style={{ width: "30px", height: "30px", borderRadius: "50%", objectFit: 'contain' }} />
                                </TwitterShareButton>
                                {/* instagram share button */}
                                <div style={{ marginLeft: 15, marginRight: 25, alignSelf: 'center', marginTop: -10 }}>
                                    <a href={`https://www.instagram.com/`} target="_blank">
                                        <img src="https://cdn-icons-png.freepik.com/512/4103/4103007.png" alt="Instagram" style={{ width: "30px", height: "30px", borderRadius: "50%", objectFit: 'contain' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                    {windows.width < 500 && (
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <FacebookShareButton url={window.location.href} title='I’ve joined Lynkd,  "The Real Social Commerce," where shopping and social interaction unite! Follow me for the latest trends and exclusive content. Join now and experience the future of social commerce. Let’s connect!'>
                                <FacebookIcon size={30} round />
                            </FacebookShareButton>
                            <WhatsappShareButton url={window.location.href} title='I’ve joined Lynkd,  "The Real Social Commerce," where shopping and social interaction unite! Follow me for the latest trends and exclusive content. Join now and experience the future of social commerce. Let’s connect!'>
                                <WhatsappIcon size={30} round />
                            </WhatsappShareButton>
                            <TwitterShareButton url={window.location.href} title='I’ve joined Lynkd,  "The Real Social Commerce," where shopping and social interaction unite! Follow me for the latest trends and exclusive content. Join now and experience the future of social commerce. Let’s connect!'>
                                <img src="https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1725753600&semt=ais_hybrid" alt="Twitter" style={{ width: "30px", height: "30px", borderRadius: "50%", objectFit: 'contain' }} />
                            </TwitterShareButton>
                            {/* instagram share button */}
                            <div style={{ marginLeft: 15 }}>
                                <a href={`https://www.instagram.com/`} target="_blank">
                                    <img src="https://cdn-icons-png.freepik.com/512/4103/4103007.png" alt="Instagram" style={{ width: "30px", height: "30px", borderRadius: "50%", objectFit: 'contain' }} />
                                </a>
                            </div>
                        </div>
                    )
                    }
                </div>
            ) : (
                <div style={styles.error}>
                    {/* Error Icon */}
                    <svg
                        fill="red"
                        style={{ width: "80px", height: "80px", marginBottom: "20px" }}
                        viewBox="0 0 24 24"
                    >
                        <circle cx="12" cy="12" r="10" stroke="red" strokeWidth="2" fill="none" />
                        <line x1="15" y1="9" x2="9" y2="15" stroke="red" strokeWidth="2" fill="none" />
                        <line x1="9" y1="9" x2="15" y2="15" stroke="red" strokeWidth="2" fill="none" />
                    </svg>

                    <h2>Submission Error</h2>
                    <p style={{ fontSize: "1.1em" }}>{message || defaultErrorMessage}</p>
                </div>
            )}
        </div>
    );
};

// Styling (Inline for simplicity)
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fafafa',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        textAlign: 'center',
    },
    success: {
        // color: '#0F27BD',
        textAlign: 'left',
    },
    error: {
        color: 'red',
    },
};

export default SubmitResponse;