import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css"; // Import the library styles
import PhoneInput from "react-phone-input-2";
import "./PhoneNumberInput.css";
const PhoneNumberInput = (props) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState("in"); // Default ISO2 code for India
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (phoneNumber !== "") {
            setIsFocused(true);
        } else {
            setIsFocused(false);
        }
    }, [phoneNumber]);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => {
        if (phoneNumber === "") setIsFocused(false);
    };

    const handlePhoneChange = (value, country) => {
        const dialCode = `+${country.dialCode}`;
        const formattedNumber = value.replace(dialCode, "").trim();
        setPhoneNumber(formattedNumber);
        setCountryCode(country.iso2);
        const fullNumber = value;
        if (props.onChange) {
            props.onChange({ target: { value: "+" + fullNumber } });
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: 20 }}>
            <PhoneInput
                country={countryCode}
                value={`${countryCode}${phoneNumber}`}
                onChange={(value, country) => handlePhoneChange(value, country)}
                inputStyle={{
                    fontSize: 16,
                    marginBottom: 10,
                }}
                dropdownStyle={{
                    maxHeight: "150px",
                    textAlign: "left", // Align dropdown items to the left
                    fontFamily: 'Montserrat'
                }}
                inputClass="floating-input"
                containerClass="phone-input-container"
                placeholder={props.placeholderText || "Phone Number"}
            />
        </div>
    );
};

export default PhoneNumberInput;
